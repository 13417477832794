@import-normalize;
/* bring in normalize.css styles */

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body {
    height: 100%;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

ul,
ol,
li {
    list-style-type: none;
}
